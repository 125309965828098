import { BaseObservable } from "../_BaseObservable"
import { EMessageType, IMessage, IObserver } from "@types"

// TODO: переделать отписку
export class MessageObserver extends BaseObservable<IObserver<EMessageType>> {
  protected notify(): void
  protected notify(message: IMessage): void
  protected notify(message?: IMessage): void {
    if (!message) return

    this.observers.forEach((observer): void => {
      const { type, cb } = observer

      if (Array.isArray(type)) {
        if (!type.includes(message.type)) return
      } else {
        if (type !== message.type) return
      }

      cb(message.data)
    })
  }

  sendMessage = (message: IMessage): void => {
    this.notify(message)
  }

  off<T extends EMessageType>(obs: IObserver<T> | IObserver<T>[]): void {
    if (Array.isArray(obs)) {
      const stringifiedObs = obs.map((o) => JSON.stringify(o))

      for (let i = 0; i < this.observers.length; i++) {
        if (!stringifiedObs.length) return

        const strObs = JSON.stringify(this.observers[i])

        if (stringifiedObs.includes(strObs)) {
          this.observers.splice(i, 1)

          const targetIndex = stringifiedObs.indexOf(strObs)
          stringifiedObs.splice(targetIndex, 1)
        }
      }

      return
    }

    this.observers.forEach((observer, index) => {
      if (observer.cb === obs.cb) {
        this.observers.splice(index, 1)
      }
    })
  }
}
