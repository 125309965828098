interface IRawRegion {
  id: number
  ru: string
  eng: string
  kg: string
}

export interface IParsedRegion {
  ru: string
  en: string
  kg: string
}

export function parseRegions(rawRegions: IRawRegion[]): Record<string, IParsedRegion> {
  const _regions: Record<string, IParsedRegion> = {}

  for (let i = 0; i < rawRegions.length; i++) {
    const item = rawRegions[i]

    _regions[i] = { ru: item.ru, en: item.eng, kg: item.kg }
  }

  return _regions
}
