// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ILObj = Record<string, any>

export enum ELang {
  RU = "RU",
  EN = "EN",
  KG = "KG",
}

export interface ILocale {
  language: ELang
  ui: ILObj
  header: ILObj
  infoBar: ILObj
  map: ILObj
  signals: ILObj
  units: ILObj
  mapFilters: ILObj
  activityTable: ILObj
  dashboard: ILObj
}
