import { ELang, ICoord } from "@types"

function formatTime(time: string): string {
  const appendZero = (value: number): string => (value > 9 ? value.toString() : `0${value}`)

  const date = new Date(time)

  const day = appendZero(date.getDate())
  const month = appendZero(date.getMonth() + 1)
  const year = date.getFullYear()
  const hours = appendZero(date.getHours())
  const minutes = appendZero(date.getMinutes())
  const seconds = appendZero(date.getSeconds())

  return [day, month, year].join(".") + " " + [hours, minutes, seconds].join(":")
}

function parseDDtoDMSString(coord: ICoord, lang: ELang = ELang.RU): readonly [string, string] {
  const isEN = lang === ELang.EN

  const ddToDms = (c: number): string => {
    const ddAbs = Math.abs(c)
    const ddWh = Math.floor(ddAbs)

    const min = (ddAbs - ddWh) * 60
    const minWh = Math.floor(min)

    const sec = (min - minWh) * 60

    return ddWh + "°" + minWh + "'" + sec.toFixed(2) + '"'
  }

  const { n, s, w, e } = {
    n: isEN ? "N" : "С",
    s: isEN ? "S" : "Ю",
    w: isEN ? "W" : "З",
    e: isEN ? "E" : "В",
  }

  const dirLat = coord.lat < 0 ? s : n
  const dirLng = coord.lng < 0 ? w : e

  return [`${ddToDms(coord.lat)}${dirLat}`, `${ddToDms(coord.lng)}${dirLng}`] as const
}

export { formatTime, parseDDtoDMSString }
