import { EAll, ETimeOptions, ILocale, TTimeOptions } from "@types"

const DAY_IN_MS = 86_400_000

function isToday(dateString: string): boolean {
  const date = new Date(dateString)
  const now = new Date()

  return (
    date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
  )
}

const getTimeSelectOptions = (l: ILocale): Record<TTimeOptions, string> => {
  const { allTime, today, threeDays, week, month, year } = l.mapFilters

  return {
    [EAll.ALL]: allTime,
    [ETimeOptions.TODAY]: today,
    [ETimeOptions.THREE_DAYS]: threeDays,
    [ETimeOptions.WEEK]: week,
    [ETimeOptions.MONTH]: month,
    [ETimeOptions.YEAR]: year,
  }
}

function getTimeEValue(quakeTime: string): ETimeOptions | EAll {
  const quakeTimeInMs = new Date(quakeTime).getTime()
  const _isToday = isToday(quakeTime)

  if (_isToday) return ETimeOptions.TODAY
  if (isBehindDays(quakeTimeInMs, 5)) return ETimeOptions.THREE_DAYS
  if (isBehindDays(quakeTimeInMs, 7)) return ETimeOptions.WEEK
  if (isBehindDays(quakeTimeInMs, 30)) return ETimeOptions.MONTH
  if (isBehindDays(quakeTimeInMs, 365)) return ETimeOptions.YEAR

  return EAll.ALL
}

function isBehindDays(time: number, dayCount: number): boolean {
  return time > Date.now() - DAY_IN_MS * dayCount
}

export { isToday, getTimeSelectOptions, getTimeEValue }
