import { IQuake, IStation } from "./_map"

interface ITick {
  tick: number
  progress: number
}

export enum EMessageType {
  QUAKE = "quake",
  STATION = "station",
  AI = "ai",
  TICK = "tick",
  STOP = "stop",
}

interface IDashboardParams {
  index: number
  probability: number
}

interface IBounds {
  north: number
  south: number
  east: number
  west: number
}

interface IAIData extends IDashboardParams {
  index: number
  probability: number
  accuracy: number
  false_positives: number
  false_negatives: number
  precision: number
  recall: number
  bounds: IBounds
}

interface IDataType {
  [EMessageType.QUAKE]: Partial<IQuake>
  [EMessageType.STATION]: Partial<IStation>
  [EMessageType.AI]: Partial<IAIData>
  [EMessageType.TICK]: ITick
  [EMessageType.STOP]: undefined
}

interface IMessage {
  type: EMessageType
  time: number
  data: IDataType[EMessageType]
}

// TODO: fix types
interface IObserver<T extends EMessageType> {
  type: T
  cb: (data: IDataType[T]) => void
}

export enum EDemoSpeed {
  X1 = 1,
  X2 = 2,
  X4 = 4,
  X10 = 10,
}

export type { ITick, IDashboardParams, IAIData, IDataType, IMessage, IObserver }
