export abstract class BaseObservable<T> {
  protected observers: T[] = []

  on(obs: T | T[]): void {
    if (Array.isArray(obs)) {
      this.observers.push(...obs)
      return
    }

    this.observers.push(obs)
  }

  off(obs: T | T[]): void {
    if (Array.isArray(obs)) {
      this.observers.filter((item) => !obs.includes(item))
      return
    }

    this.observers.filter((item) => item !== obs)
  }

  protected abstract notify(): void
}
