import { MessageObserver } from "./_MessageObserver"
import { EDemoSpeed, EMessageType, IMessage, IObserver, IQuake } from "@types"

const MAX_TICK = 299

type IEventsObj = Record<string, IMessage[]>

export class DemoScript {
  private eventsObj: IEventsObj

  private messageObserver: MessageObserver

  private intervalId: NodeJS.Timer | null = null
  private intervalTime = 1000
  private tick = 0
  private isAtStart = true

  constructor(events: IMessage[]) {
    this.eventsObj = parseEvents(events)

    this.messageObserver = new MessageObserver()
  }

  get progress(): number {
    return Math.round((this.tick / MAX_TICK) * 100)
  }

  setEvents = (newEvents: IMessage[]): void => {
    this.stop()
    this.eventsObj = parseEvents(newEvents)
  }

  start = (): void => {
    if (this.intervalId) return
    if (this.isAtStart) this.isAtStart = false

    this.intervalId = setInterval(() => {
      if (this.tick > MAX_TICK) this.pause()

      this.sendMessage()

      this.tick += 1
    }, this.intervalTime)
  }

  private sendMessage(): void {
    const timestamp = this.tick.toString()

    this.messageObserver.sendMessage({
      type: EMessageType.TICK,
      time: this.tick,
      data: { tick: this.tick, progress: this.progress },
    })

    if (!this.eventsObj[timestamp]) return

    this.eventsObj[timestamp].forEach((event) => {
      const shouldSetTime = event.type === EMessageType.QUAKE && (event.data as Partial<IQuake>).time !== undefined
      const e = shouldSetTime ? { ...event, data: setTime(event.data as IQuake) } : event

      this.messageObserver.sendMessage(e)
    })
  }

  pause = (): void => {
    if (!this.intervalId) return

    clearInterval(this.intervalId)
    this.intervalId = null
  }

  stop = (): void => {
    if (this.isAtStart) return

    this.pause()
    this.tick = 0
    this.messageObserver.sendMessage({ type: EMessageType.TICK, time: 0, data: { tick: 0, progress: 0 } })
    this.messageObserver.sendMessage({ type: EMessageType.STOP, time: 0, data: undefined })

    this.isAtStart = true
  }

  setSpeed = (speed: EDemoSpeed): void => {
    const time = 1000 / speed

    if (this.intervalTime === time) return

    const shouldContinue = this.intervalId !== null

    this.pause()
    this.intervalTime = time

    shouldContinue && this.start()
  }

  on = (obs: IObserver<EMessageType> | IObserver<EMessageType>[]): void => {
    this.messageObserver.on(obs)
  }

  off = (obs: IObserver<EMessageType> | IObserver<EMessageType>[]): void => {
    this.messageObserver.off(obs)
  }
}

function parseEvents(events: IMessage[]): IEventsObj {
  const parsedEvents: IEventsObj = {}

  for (let i = 0; i < events.length; i++) {
    const time = events[i].time

    if (!parsedEvents[time]) parsedEvents[time] = []

    parsedEvents[time].push(events[i])
  }

  return parsedEvents
}

function setTime(data: IQuake): IQuake {
  return { ...data, time: new Date(Date.now()).toISOString() } as IQuake
}
