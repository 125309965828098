/* eslint-disable @typescript-eslint/no-explicit-any */

/** Тип сеттера React.SetState */
type TSetState<T> = React.Dispatch<React.SetStateAction<T>>

/* Тип любой функции */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TAnyArgs = any[]
type TAnyFunc = (...args: TAnyArgs) => any

/* Тип любого объекта */
type Primitives = symbol | string | number | boolean | null | undefined
type TAnyObject<Key extends string | number | symbol = string, Value = Primitives> = Record<Key, Value>

export enum EMode {
  STANDBY = "STANDBY",
  DEMO = "DEMO",
}

export type { TSetState, TAnyArgs, TAnyFunc, Primitives, TAnyObject }
