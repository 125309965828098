enum EAll {
  ALL = "All",
}

enum EMagValues {
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
  SIX = "SIX",
}

enum ELayers {
  STATIONS = "STATIONS",
  QUAKES = "QUAKES",
  PRED_AREA = "PRED_AREA",
  BUILDINGS = "BUILDINGS",
  POPULATION = "POPULATION",
  TAILINGS = "TAILINGS",
}

enum ETimeOptions {
  TODAY = "TODAY",
  THREE_DAYS = "THREE_DAYS",
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

type TTimeOptions = ETimeOptions | EAll

interface IMapFilterValues {
  layers: (EAll | ELayers)[]
  mag: (EAll | EMagValues)[]
  time: (EAll | ETimeOptions)[]
}

type TSetMapFilters = (newFilters: Partial<IMapFilterValues>) => void

export { EAll, EMagValues, ELayers, ETimeOptions }

export type { IMapFilterValues, TTimeOptions, TSetMapFilters }
