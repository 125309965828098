import { MessageObserver } from "./_MessageObserver"
import { EMessageType, IObserver } from "@types"

export class WaveformLauncher {
  private messageObserver = new MessageObserver()
  private intervalId: NodeJS.Timer | null = null

  start = (): void => {
    if (this.intervalId) return

    this.intervalId = setInterval(() => {
      this.sendMessage()
    }, 1000)
  }

  private sendMessage(): void {
    this.messageObserver.sendMessage({
      type: EMessageType.TICK,
      time: 0,
      data: { tick: 0, progress: 0 },
    })
  }

  stop = (): void => {
    if (!this.intervalId) return

    clearInterval(this.intervalId)
    this.intervalId = null
  }

  on = (obs: IObserver<EMessageType> | IObserver<EMessageType>[]): void => {
    this.messageObserver.on(obs)
  }

  off = (obs: IObserver<EMessageType> | IObserver<EMessageType>[]): void => {
    this.messageObserver.off(obs)
  }
}
